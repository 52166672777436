<template>
  <div class="hzkHome">
    <div class="a-top">
      <!--      <img v-show="swi == 1" class="div" :src="res[0]?.pictureUrl" alt="" />-->
      <!--      <img v-show="swi == 2" class="div" :src="res[1]?.pictureUrl" alt="" />-->
      <!--      <img v-show="swi == 3" class="div" :src="res[2]?.pictureUrl" alt="" />-->
      <!--      <img v-show="swi == 4" class="div" :src="res[3]?.pictureUrl" alt="" />      -->
      <div class="home_banner_bg">
        <div class="a-top-c">
          <!-- 头部轮播 -->
          <div class="top_banner_wrap">
            <transition name="el-fade-in-linear">
              <img @click="tozhiwei(res[0].recruitmentId)" v-show="swi == 1" :src="res[0]?.pictureUrl" alt=""
                class="img" />
            </transition>
            <transition name="el-fade-in-linear">
              <img @click="tozhiwei(res[1].recruitmentId)" v-show="swi == 2" :src="res[1]?.pictureUrl" alt=""
                class="img" />
            </transition>
            <transition name="el-fade-in-linear">
              <img @click="tozhiwei(res[2].recruitmentId)" v-show="swi == 3" :src="res[2]?.pictureUrl" alt=""
                class="img" />
            </transition>
            <transition name="el-fade-in-linear">
              <img @click="tozhiwei(res[3].recruitmentId)" v-show="swi == 4" :src="res[3]?.pictureUrl" alt=""
                class="img" />
            </transition>
          </div>
          <!-- 轮播图右侧list -->
          <div class="top_banner_list_wrap">
            <li class="every_banner_list" @click="tozhiwei(res[0].recruitmentId)" @mouseleave="staswi"
              @mouseover="switt(1)" :style="
                swi == 1
                  ? 'background: linear-gradient(90deg, #2570cb 0%, #246ecb 100%);color:#fff;'
                  : ''
              ">
              <span>{{ res[0]?.name }}</span>
            </li>
            <li class="every_banner_list" @click="tozhiwei(res[1].recruitmentId)" @mouseleave="staswi"
              @mouseover="switt(2)" :style="
                swi == 2
                  ? 'background: linear-gradient(90deg, #2570cb 0%, #246ecb 100%);color:#fff;'
                  : ''
              ">
              <span>{{ res[1]?.name }}</span>
            </li>
            <li class="every_banner_list" @click="tozhiwei(res[2].recruitmentId)" @mouseleave="staswi"
              @mouseover="switt(3)" :style="
                swi == 3
                  ? 'background: linear-gradient(90deg, #2570cb 0%, #246ecb 100%);color:#fff;'
                  : ''
              ">
              <span>{{ res[2]?.name }}</span>
            </li>
            <li class="every_banner_list" @click="tozhiwei(res[3].recruitmentId)" @mouseleave="staswi"
              @mouseover="switt(4)" :style="
                swi == 4
                  ? 'background: linear-gradient(90deg, #2570cb 0%, #246ecb 100%);color:#fff;'
                  : ''
              ">
              <span>{{ res[3]?.name }}</span>
            </li>
            <div class="mySpan" @click="jobWanted('')">
              <div class="spanSon">更多职位公告</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 头部搜索框 -->
      <div class="top_search_wrap">
        <div class="top_search">
          <div class="searchAnnouncement">
            <!--            <img @click="change(searchinfo)" src="../assets/img/search123.svg" alt="6" height="20px" width="20px"-->
            <!--                 style="text-align: center;vertical-align: middle;cursor: pointer;" />-->
            <span class="search_title">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.75 15C12.2018 15 15 12.2018 15 8.75C15 5.29822 12.2018 2.5 8.75 2.5C5.29822 2.5 2.5 5.29822 2.5 8.75C2.5 12.2018 5.29822 15 8.75 15Z"
                  stroke="#424242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17.5003 17.5L13.167 13.1667" stroke="#424242" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span class="search_title_text">搜索职位公告：</span>
            </span>
            <input type="text" :placeholder="currentPlaceholder" v-model="currentValue" @keyup.enter="todetailPost" />
          </div>
          <div class="buttonGroup">
            <span class="divided_line"></span>
            <button class="btn_find" @click="jobWanted('')">我要求职</button>
            <button class="btn_recruit" @click="RCenroll">我要招聘</button>
          </div>

        </div>
        <!-- <div class="" style="height:48px"></div> -->
        <!-- <ul style="width:1200px ;margin :0 auto; height:40px"> -->
        <!-- <div class="lititle">热门关键词 :</div> -->
        <!-- <li v-for="(item, index) in hotlist" :key="index" @click="change(item)"> -->
        <!-- {{ item }} -->
        <!-- </li> -->
        <!-- </ul> -->
      </div>
    </div>

    <div class="contain">
      <!-- 热招职位 -->
      <!-- <div class="hotPosition">
        <div class="hpT">
          <div class="hpTL">
            <div class="underline"></div>
            <div class="title">热招职位公告</div>
          </div>
          <div class="hpTR">
            <div @click="RCenroll">我要发布 <p style="margin-top: -2px;margin-left:5px">→</p></div>
            <img style="cursor:pointer;height:38px" @click="RCenroll" src="../assets/img/Frame 876.svg" alt="">
            <div>丨</div>
            <div @click="$router.push({ name: 'Posts' })">更多 > </div>
          </div>
        </div>
        <div class="underlineOut"></div>
        <div class="hpB">
          <div v-for="item in hotPosition" :key="item.id" class="Bbox" @click="toAnnouncement(item.id)">
            <div class="left">热招</div>
            <div class="right" :title="item.recruitmentPosition">
              {{ item.recruitmentPosition }}
            </div>
          </div>
        </div>
      </div> -->

      <!-- 推荐优质单位 -->
      <div class="recommendInstitution">
        <div class="recommendInstitution_wrap">
          <div class="header_info">
            <div class="left_title flex_wrap">
              <div class="">
                <img src="../img/Group 1870.png" width="18px" alt="">
              </div>
              <div class="p_24">
                热招单位推荐
              </div>
              <div class="">
                <img src="../img/Group 1871.png" width="18px" alt="">
              </div>
            </div>
          </div>
          <div class="nav">
            <div v-for="item in locationList" :key="item.id" class="item imge_info"
              @click="getRecommendInstitution(item.location, item.id)">
              <div :class="item.id == blueId ? 'blue' : ''" style="width:70px">
                {{ item.location }}
              </div>
              <div class="img_info">
                <img src="../img/image 8.png" alt="">
              </div>
            </div>
          </div>
          <div class="body">
            <div v-for="item in recommendInstitution" :key="item.id" class="item offset_up"
              @click="toUnit(item.name, item.id)">
              <div class="img">
                <img :src="item.picture" alt="">img
              </div>
              <div class="name">{{ item.name }}</div>
              <!--            <div class="elName">{{ item.englishName ? item.englishName : '' }}</div>-->
              <div class="num">
                <div>{{ item.jobLast }}</div>
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="item flex_wrap more_unit" @click="$router.push({ name: 'Universitypage' })">
              <div class="font_color">查看更多单位</div>
              <div class="ml_11">
                <img src="../img/more_unit.svg" width="20px" height="20px" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 引才专项计划 -->
      <!-- <div class="introduce">
        <div class="header">
          <div class="left">引才专项计划</div>
          <div class="right">/ 精准化靶向型引才</div>
        </div>
        <div class="cover">
          <div class="nav">
            <div :class="introId == 1 ? 'blue item' : 'item'" @click="getRenCaiZhuanXiang('', 1)">全部</div>
            <div :class="introId == 2 ? 'blue item' : 'item'" @click="getRenCaiZhuanXiang('硕士点建设单位', 2)">硕士点建设单位</div>
            <div :class="introId == 3 ? 'blue item' : 'item'" @click="getRenCaiZhuanXiang('博士点建设单位', 3)">博士点建设单位</div>
            <div :class="introId == 4 ? 'blue item' : 'item'" @click="getRenCaiZhuanXiang('高水平学校建设高校', 4)">双高计划建设单位</div>
          </div>
          <div class="body">
            <div v-for="(item, index) in wellChosen" :key="index" class="item" @click="toUnit(item.name, item.id)">
              <div class="img">
                <img src="" alt="">
              </div>
              <div class="name">
                <div class="left">{{ item.name }}</div>
              </div>
              <div class="content">
                {{ item.intro }}
              </div>
              <div class="tag">{{ item.type }}</div>
            </div>
          </div>
          <div class="footer">
            <div class="item" @click="$router.push('/LeadSpecial')">
              <span>查看更多引才专项计划单位 > </span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- 精选公告 -->
      <div class="wellChosen_bg">
        <div class="position_bc">
          <!-- <img src="../img/wellChosen_bg.png" alt=""> -->
          <div class="header_title">
            <div>精选公告</div>
            <div class="ml_16">
              <img src="../img/Group 1862.png" width="18px" height="28px" alt="">
            </div>
          </div>
        </div>

        <div class="wellChosen">

          <div class="nav">
            <div v-for="item in wellChosenTags" :key="item.id" :class="item.id == wellBlueId ? 'wellBlue item' : 'item'"
              @click="select(item.name, item.id)">
              {{ item.name }}
              <div v-if="item.id == wellBlueId"></div>
            </div>
          </div>
          <div class="body">
            <div v-for="item in job1" :key="item.id" class="item padding_0" @click="skip(item.id)">
              <div class="top_bc">
                <div class="title" :title="item.recruitmentPosition">{{ item.recruitmentPosition }}</div>
                <div class="tag">
                  <!-- <div>情报与档案</div> -->
                  <!--     {{ item }} -->
                  <div class="every_tag" v-if="item.demandDiscipline && item.demandDiscipline[0]">{{
                    item.demandDiscipline[0] }}</div>
                  <div class="every_tag" v-if="item.demandDiscipline && item.demandDiscipline.length > 2">{{
                    item.demandDiscipline[1] }} 等</div>
                  <div class="every_tag" v-if="item.demandDiscipline && item.demandDiscipline.length == 2">{{
                    item.demandDiscipline[1] }}</div>
                  <div class="every_tag" v-if="item.degreeRequired && item.degreeRequired.length >= 2">{{
                    item.degreeRequired[0] }}及以上</div>
                  <div class="every_tag" v-if="item.degreeRequired && item.degreeRequired.length == 1">{{
                    item.degreeRequired[0] }}</div>
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  <div class="logo">
                    <img :src="item.logo" alt="">
                  </div>
                  <el-tooltip :disabled="item.unitName.length < 12" placement="bottom" :visible-arrow="false">
                    <div class="name">{{ item.unitName }}</div>
                    <div slot="content">{{
                      item.unitName }}
                    </div>
                  </el-tooltip>

                </div>
                <div class="right">{{ item.unitAddress[0] }} · {{ item.unitAddress[1] }}</div>
              </div>
            </div>
          </div>
          <div class="footer ">
            <div class="item more_btn" @click="moreJobInformation(wcName)">
              <div class="flex_wrap">
                <div>更多{{ wcName }}招聘信息</div>
                <div class="ml_33">
                  <img src="../img/more_btn.svg" width="18px" height="18px" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="contain1">
        <div class="c-left">

          <div class="c-center">

            <ul class="e-top">
              <li @click="cheacked = 1" :class="cheacked == 1 ? 'liact' : ''">
                热招
              </li>
              <li @click="cheacked = 2" :class="cheacked == 2 ? 'liact' : ''">
                双一流招聘
              </li>
              <li @click="cheacked = 3" :class="cheacked == 3 ? 'liact' : ''">
                科研院所招聘
              </li>
              <span @click="huanyipi">
                换一批
                <i class="fa fa-refresh" aria-hidden="true"></i>

              </span>
              <div :class="'div' + cheacked"></div>
            </ul>

            <div class="fflex">
              <div :class="'ffflex' + cheacked">

                <template>
                  <div class="first_wrap">
                    <el-empty v-if="empty" description="未搜索到内容"
                      style="padding: 40px 267px !important; margin-top:-200px"></el-empty>
                    <ul v-else class="e-center" v-loading="loading1">
                      <li v-for="item in hot6" :key="item.id" @click="skip(item.institutionId)">
                        <div class="img">
                          <img :src="item.logo" alt="" width="80px" />
                        </div>
                        <div class="span">
                          <span class="span_title">
                            <img src="../assets/img/city123.svg" alt="" width="17px" />
                            {{ item.unitName }}
                          </span>
                          <p style="overflow:hidden;width:350px">{{ item.recruitmentPosition }} &nbsp;&nbsp;&nbsp;&nbsp; </p>
                        </div>
                        <div class="ii" style="color: black">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ item.unitAddress ? item.unitAddress[1] : '城市' }}
                        </div>
                        <div class="creat_time">{{ item.date }}</div>

                      </li>
                    </ul>
                  </div>
                  <div class="first_info">
                    <el-empty v-if="empty" description="未搜索到内容"
                      style="padding: 40px 267px !important; margin-top:-200px"></el-empty>
                    <ul v-else class="e-center" v-loading="loading1">
                      <li v-for="item in hot6" :key="item.id" @click="skip(item.institutionId)">
                        <div class="img">
                          <img :src="item.logo" alt="" width="80px" />
                        </div>
                        <div class="span">
                          <span class="span_title">
                            <img src="../assets/img/city123.svg" alt="" width="17px" />
                            {{ item.unitName }}
                          </span>
                          <p style="overflow:hidden;width:385px">{{ item.recruitmentPosition }}</p>
                        </div>
                        <div class="ii" style="color: black">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ item.unitAddress ? item.unitAddress[1] : '城市' }}
                        </div>
                        <div class="creat_time">{{ item.date }}</div>

                      </li>
                    </ul>
                  </div>
                  <div class="first_wrap">
                    <el-empty v-if="empty2" description="未搜索到内容"
                      style="padding: 40px 267px !important; margin-top:-200px"></el-empty>
                    <ul class="e-center" v-loading="loading2">
                      <li v-for="item in job1" :key="item.id" @click="skip(item.id)">
                        <div class="img">
                          <img :src="item.logo" alt="" width="80px" />
                        </div>
                        <div class="span">
                          <span class="span_title">
                            <img src=" ../assets/img/city123.svg" alt="" width="17px" />
                            {{ item.unitName }}
                          </span>
                          <p style="overflow:hidden;width:350px">{{ item.recruitmentPosition }}</p>
                        </div>
                        <div class="ii" style="color: black">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ item.unitAddress ? item.unitAddress[1] : '城市' }}
                        </div>
                        <div class="creat_time">{{ item.date }}</div>

                      </li>
                    </ul>
                  </div>
                  <div class="first_info">
                    <el-empty v-if="empty2" description="未搜索到内容"
                      style="padding: 40px 267px !important; margin-top:-200px"></el-empty>
                    <ul class="e-center" v-loading="loading2">
                      <li v-for="item in job1" :key="item.id" @click="skip(item.id)">
                        <div class="img">
                          <img :src="item.logo" alt="" width="80px" />
                        </div>
                        <div class="span">
                          <span class="span_title">
                            <img src=" ../assets/img/city123.svg" alt="" width="17px" />
                            {{ item.unitName }}
                          </span>
                          <p style="overflow:hidden;width:385px">{{ item.recruitmentPosition }}</p>
                        </div>
                        <div class="ii" style="color: black">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ item.unitAddress ? item.unitAddress[1] : '城市' }}
                        </div>
                        <div class="creat_time">{{ item.date }}</div>

                      </li>
                    </ul>
                  </div>
                  <div class="first_wrap">
                    <el-empty v-if="empty3" description="未搜索到内容"
                      style="padding: 40px 267px !important; margin-top:-200px"></el-empty>
                    <ul v-else class="e-center" v-loading="loading3">
                      <li v-for="item in hospitalname" :key="item.id" @click="skip(item.institutionId)">
                        <div class="img">
                          <img :src="item.logo" alt="" width="80px" />
                        </div>
                        <div class="span">
                          <span class="span_title">
                            <img src="../assets/img/city123.svg" alt="" width="17px" />
                            {{ item.unitName }}</span>
                          <p style="overflow:hidden;width:350px">{{ item.recruitmentPosition }}</p>
                        </div>
                        <div class="ii" style="color: black">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ item.unitAddress ? item.unitAddress[1] : '城市' }}
                        </div>
                        <div class="creat_time">{{ item.date }}</div>

                      </li>
                    </ul>
                  </div>
                  <div class="first_info">
                    <el-empty v-if="empty3" description="未搜索到内容"
                      style="padding: 40px 267px !important; margin-top:-200px"></el-empty>
                    <ul v-else class="e-center" v-loading="loading3">
                      <li v-for="item in hospitalname" :key="item.id" @click="skip(item.institutionId)">
                        <div class="img">
                          <img :src="item.logo" alt="" width="80px" />
                        </div>
                        <div class="span">
                          <span class="span_title">
                            <img src="../assets/img/city123.svg" alt="" width="17px" />
                            {{ item.unitName }}</span>
                          <p style="overflow:hidden;width:385px">{{ item.recruitmentPosition }}</p>
                        </div>
                        <div class="ii" style="color: black">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ item.unitAddress ? item.unitAddress[1] : '城市' }}
                        </div>
                        <div class="creat_time">{{ item.date }}</div>

                      </li>
                    </ul>
                  </div>
                </template>


              </div>


            </div>

          </div>
        </div>
        <div class="c-right">
          <div class="q-top">
            <div @click="RCenroll">
              <img src="../assets/img/zhaopin.png" alt="" width="350" height="157" />
            </div>
            <div @click="change('')">
              <img src="../assets/img/requestInfo.png" alt="" width="350" height="157" />
            </div>
          </div>
          <div class="q-bottom">
            <div class="w-top">
              <p>职位优选</p>
              <span @click="change('')">
                更多 >

              </span>
            </div>
            <ul class="w-bottom">
              <li v-for="(item, key, index) in optimization" :key="index" @click="skip(item.recruitmentModuleId)">
                <div class="li1">
                  <span :title="item.recruitmentPosition">{{
                    item.recruitmentPosition
                  }}</span>
                </div>
                <div class="li2">
                  <span>{{ item.unitAddress[0] }}</span>
                  <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  <span>{{ item.degreeRequired }}</span>
                  <span class="p">{{ item.postType |  ellipsis}}</span>
                </div>
                <div class="li3">
                  <div>
                    <img :src="item.logo" alt="" height="38px" />
                  </div>
                  <span>
                    <p>{{ item.unitName }}</p>
                    <p>{{ item.date }}</p>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </div>
    <!--热门地区  人才资讯 -->
    <div class="bottom bottom_bc">
      <!-- 热门地区 -->
      <div class="first">
        <div class="b-title">
          <div class="title_wrap">
            <!-- <div class="right_border"></div> -->
            <div style="padding-right:11px">
              <img src="../img/Group 1878.png" height="22px" alt="">
            </div>
            热门地区
          </div>
          <span class="right_more" @click="asdasd1">更多 ></span>
        </div>
        <div class="bottom_first_wrap">
          <div class="b-carousel">
            <el-carousel height="240px" arrow="always" indicator-position="none">
              <el-carousel-item v-for="(item, index) in getHotCity" :key="index">
                <div class="b-carousel1">
                  <div v-for="i in item" :key="i.id" :style="'background-image: url(' + i?.pictureUrl + ');'">
                    <div>
                      <span @click="openForPositions(i.city)">在招职位</span>
                      <span @click="asdasd(i.city)" style="left: 140px">地区政策</span>
                    </div>
                    <span>{{ i.city }}</span>
                    <p><br /></p>
                    <p>{{ i.cityWords }}</p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <!-- 人才资讯 -->
      <div class="second">
        <div class="second_title">
          <div class="right_title_wrap">
            <div><img src="../img/编组 54.png" alt=""></div>
            <div class="m_12">人才资讯</div>
            <div><img src="../img/icon_info1.png" alt=""></div>
          </div>
          <div class="change_wrap" @click="huanyipi1">
            <div style="margin-right:10px">换一批</div>
            <div>
              <img src="../img/change_wrap.png" width="10px" alt="">
            </div>
          </div>
        </div>

        <!-- <img style="margin-top: 80px;cursor: pointer;" @click="tozhiwei(bigimgid)" :src="bigimgurl" alt="" width="100%" /> -->
        <div class="bottom_last_content">
          <div class="b-center">
            <div class="b-left">
              <el-carousel ref="carousel" :interval="3000" arrow="never" height="338px" indicator-position="none"
                @change="carousel">
                <el-carousel-item v-for="item in res1" :key="item.id">
                  <img @click="skip66(item.id)" class="img_wrap" style="" :src="item.imageUrl" alt="" />
                </el-carousel-item>
              </el-carousel>
              <div class="bb">
                <span @click="skip66(res1[swindex].id)">{{ res1[swindex]?.title }}</span>
                <ul>
                  <li :class="swindex == 0 ? 'li' : ''" @click="enterli(0)"></li>
                  <li :class="swindex == 1 ? 'li' : ''" @click="enterli(1)"></li>
                  <li :class="swindex == 2 ? 'li' : ''" @click="enterli(2)"></li>
                  <li :class="swindex == 3 ? 'li' : ''" @click="enterli(3)"></li>
                  <li :class="swindex == 4 ? 'li' : ''" @click="enterli(4)"></li>
                </ul>
              </div>
            </div>
            <div class="b-reight">

              <ul class="right_list_wrap" v-loading="loading">
                <li class="right_every_list" v-for="item in getTalentInfo" :key="item.id" @click="skip66(item.id)">
                  <!-- <span style="width: 240px"></span> -->
                  <div style="    font-weight: 700;" :title="'【' + item.module1 + '】' + item.title">【{{ item.module1 }}】
                    {{ item.title }}</div>
                  <div class="content_wrap">{{ item.externalIntroduction }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="w_1200">
          <div class="more_news" @click="toMoreNews">
            <div>更多资讯</div>
            <div class="ml_33">
              <img src="../img/more_btn.svg" width="18px" height="18px" alt="">
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- <div class="rew">
      <img
        src="../assets/img/二维码.jpg"
        alt=""
        width="120px"
      />
      <span>扫码关注学国公众号<br>关注人才和科研</span>
    </div>
    <div  class="iasd" v-if="btnFlag" @click="backTop"><i class="fa fa-angle-up" aria-hidden="true"></i></div> -->
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="success_icon"><i class="el-icon-circle-check"></i></div>
      <div class="success_msg">感谢您的访问，已成功获得1积分</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->


  </div>
</template>

<script>
import jwtDecode from "jwt-decode";
export default {
  name: "Home",
  data() {
    return {
      placeholderTexts: ["人工智能", "研究院", "行政", "武汉大学"],
      currentPlaceholder: '博士后',
      currentValue: '',
      currentPlaceholderIndex: 0,
      // dialogVisible: true,
      wcName: "双一流院校",
      blueId: 1,
      introId: 1,
      wellBlueId: 1,
      bigimgurl: '',
      bigimgid: '',
      loading1: true,
      loading2: true,
      loading3: true,
      loading: true,
      userType: 1,
      list: [1, 2, 3, 4, 5, 6, 7, 8],
      swindex: 0,
      searchinfo: "",
      cheacked: 1,
      cheacked1: false,
      btnFlag: false,
      res: [],
      res1: [],
      hospitalname: [],
      currentPage1: 1,
      currentPage2: 1,
      currentPage: 1,
      currentPage3: 1,
      job1: [],
      total: 0,
      getHotCity: [[], [], []],
      getTalentInfo: "",
      getSlide: "",
      // newjob1: "",
      hot6: "",
      empty: true,
      empty2: true,
      empty3: true,
      swi: 1,
      timer: "",
      optimization: [],
      hotlist: ["博士后", "人工智能", "研究院", "行政", "武汉大学"],
      hotPosition: [],
      recommendInstitution: [],
      wellChosen: [],
      locationList: [
        {
          id: 1,
          location: "全国站"
        },
        {
          id: 2,
          location: "华东地区"
        },
        {
          id: 3,
          location: "华中地区"
        },
        {
          id: 4,
          location: "华南地区"
        },
        {
          id: 5,
          location: "华北地区"
        },
        {
          id: 6,
          location: "东北地区"
        },
        {
          id: 7,
          location: "西南地区"
        },
        {
          id: 8,
          location: "西北地区"
        }
      ],
      wellChosenTags: [
        {
          id: 1,
          name: "双一流院校"
        },
        {
          id: 2,
          name: "普通本科院校"
        },
        {
          id: 3,
          name: "高职高专院校"
        },
        {
          id: 4,
          name: "中科院系统"
        },
        {
          id: 5,
          name: "人文社科研究机构"
        },
        {
          id: 6,
          name: "自然与应用科研机构"
        },
        {
          id: 7,
          name: "企业研发机构"
        },
        {
          id: 8,
          name: "医院"
        }
      ]

    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return '';
      if (value.length > 3) {
        return value.slice(0, 3) + '...'
      }
      return value
    }
  },
  methods: {
    // 头部关键词搜索
    todetailPost() {
      if (this.currentValue == '') {
        this.$router.push({
          name: "RecommendDirectly",
          query: {
            label: this.currentPlaceholder,
          },
        });
      } else {
        this.$router.push({
          name: "RecommendDirectly",
          query: {
            label: this.currentValue,
          },
        });
      }

    },
    // 更多资讯
    toMoreNews() {
      this.$router.push("/TalentInformation")
    },
    //热招职位公告
    async getHotPosition() {
      let res = await this.institution.getHotPosition();
      this.hotPosition = res.data.recruitmentVo.rows;
    },
    //推荐优质单位
    async getRecommendInstitution(location, id) {
      this.blueId = id
      let res = await this.institution.getRecommendInstitution(location);
      this.recommendInstitution = res.data.universityListVo.rows;
    },
    //单位首页
    toUnit(name, id) {
      if (id == null) {
        return
      }
      // this.$router.push({ name: "UniversityDetail", query: { name } });
      const { href } = this.$router.resolve({
        name: "UniversityDetail",
        query: { id, name }
      });
      window.open(href, "_blank");
    },
    //引才专项计划
    async getRenCaiZhuanXiang(val, id) {
      let res = await this.institution.getRenCaiZhuanXiang({
        type: val,
        more: false
      });
      this.introId = id
      this.wellChosen = res.data.universityListVo;
    },

    // 换一批 热门地区
    huanyipi() {
      if (this.cheacked == 1) {
        this.currentPage1++;
        this.select1();
      } else if (this.cheacked == 2) {
        this.currentPage2++;
        this.select();
      } else if (this.cheacked == 3) {
        this.currentPage3++;
        this.select2();
      }
    },
    // 换一批 人才资讯
    huanyipi1() {
      this.currentPage++;
      this.getTalentInfo1();
    },
    enterli(e) {
      this.$refs.carousel.setActiveItem(e);
    },
    carousel(a) {
      this.swindex = a;
    },
    asdasd1() {
      this.$router.push({ name: "CityDetail" });
    },
    asdasd(e) {
      if (e === "上海" || e === "北京" || e === "天津" || e === "重庆") {
        this.$router.push({ path: "CityDetail", query: { name: e } });
      } else {
        // console.log("省市");
        this.$router.push({
          path: "CityDetail_city",
          query: { name: e + "市" },
        });
      }
      // this.$router.push({ name: "CityDetail" });
    },
    RCenroll() {
      // console.log(sessionStorage.getItem('token'),'token')
      // this.$router.push({ name: "RCenroll" });
      // this.$router.push({ name: "PostJob" });

        let token = this.$store.state.login.token;
      if(token) {
        let user = jwtDecode(token);
        this.userType = user.userRole;
      }
      
      if (this.userType === "0") {
        // console.log("有token");
        this.$router.push({ name: "PostJob" });
      } else {
        // console.log("无token");
        this.$router.push({ name: "LoginMain" });
      }
    },
    tip(e) {
      if (e == "") alert("请输入搜索内容");
    },
    toTalentInfo(id) {
      // this.$router.push({ path: "TalentInfo", query: { id: id } });
      const { href } = this.$router.resolve({
        path: "TalentInfo", query: { id: id }
      });
      window.open(href, '_blank');
    },
    // 去职位
    tozhiwei(e) {
      // this.$router.push({
      //   path: "job",
      //   query: {
      //     id: e,
      //   },
      // });
      const { href } = this.$router.resolve({
        path: "job",
        query: {
          id: e,
        },
      });
      window.open(href, '_blank');
    },
    // 我要求职 btn
    jobWanted(e) {
      this.$router.push({
        name: "RecommendDirectly",
        query: {
          id: e,
        },
      });
    },
    // 更多招聘职位 btn
    moreJobInformation(e) {
      this.$router.push({
        name: "RecommendDirectly",
        query: {
          label: e,
        },
      });
    },
    // 在招职位 
    openForPositions(e) {
      this.$router.push({
        name: "RecommendDirectly",
        query: {
          label: e,
        },
      });
    },
    skip(id) {
      // this.$router.push({ path: "job", query: { id: id } });
      const { href } = this.$router.resolve({
        path: "/job",
        query: {
          id: id,
        },
      });
      window.open(href, '_blank');
    },
    toAnnouncement(id) {
      const { href } = this.$router.resolve({
        path: "job",
        query: {
          id: id,
        },
      });
      window.open(href, '_blank');
    },
    // handleCurrentChange(val) {
    //   (this.currentPage = val),
    //     window.scrollTo({
    //       behavior: "smooth",
    //       top: 100,
    //     });

    //   this.select();
    // },

    async img() {
      const res = await this.api.getSlide();
      this.res = res.data.SlideShow;
      console.log(res);
    },
    async getBanner() {
      const res = await this.api.getBanner();
      console.log(res.data.banner[0])
      this.bigimgurl = res.data.banner[0].url;
      this.bigimgid = res.data.banner[0].institutionId
    },
    async optimization1() {
      // this.cheacked = true;
      // this.cheacked1 = false;
      const optimization = await this.api.optimization({
        currentPage: 1,
      });
      // console.log(optimization);
      this.optimization = optimization.data.goodJobList;
    },

    async getHotCity1() {
      const getHotCity = await this.api.getHotCity();
      // getHotCity = getHotCity.data.hotCities;
      var res = getHotCity.data.hotCities;
      for (var i = 0; i < 12; i++) {
        this.getHotCity[parseInt(i / 4)].push(res[i]);
      }
    },
    // 轮播图
    //     async getSlide1() {

    //  const getSlide = await this.api.getSlide();

    //  this.getSlide = getSlide.data;

    // },

    async select(val, id) {
      console.log(val, "val");
      console.log(id, "id");

      this.wcName = val
      this.wellBlueId = id;
      this.empty2 = false;
      // this.cheacked = true;
      // this.cheacked1 = false;
      this.loading2 = true;
      const job = await this.api.job6({
        type: val,
        // type: "双一流院校",
      });
      this.loading2 = false;
      console.log(job, "++++++++++++++++++++");
      // recruitmentVo
      this.job1 = job.data.recruitmentVo?.rows.slice(0, 6);
      console.log(this.job1);
      // this.job1.length = 6;
      this.total = job.data.recruitmentVo?.total;
      if (this.job1.length === 0) {
        this.empty2 = true;
      }
      console.log(this.job1.slice(0, 6), "job1");
    },
    async select1() {
      this.empty = false;
      this.loading1 = true;
      // this.cheacked = false;
      // this.cheacked1 = true;
      const hot6 = await this.api.hot6({
        currentPage: parseInt(this.currentPage1),
      });
      this.hot6 = hot6.data.recruitmentVoList;
      this.loading1 = false;
      // this.total = newjob.data.LastRecruitmentVo.total;
      if (this.hot6?.length === 0) {
        this.empty = true;
      }
      // console.log(newjob);
    },
    async select2() {
      this.empty3 = false;
      this.loading3 = true;
      // this.cheacked = false;
      // this.cheacked1 = true;
      const hospital = await this.api.hospital({
        currentPage: parseInt(this.currentPage3),
      });
      this.loading3 = false;
      this.hospitalname = hospital.data.recruitmentVoList;

      if (this.hospitalname?.length === 0) {
        this.empty3 = true;
      }
      // console.log(newjob);
    },

    async getTalentInfo1() {
      this.empty = false;
      this.loading = true;
      // this.cheacked = false;
      // this.cheacked1 = true;
      const getTalentInfo = await this.api.getTalentInfo({
        currentPage: parseInt(this.currentPage),
      });
      this.loading = false;
      this.getTalentInfo = getTalentInfo.data.talentInfos.rows.slice(0, 4);

      // this.total = newjob.data.LastRecruitmentVo.total;
      if (this.getTalentInfo?.length === 0) {
        this.empty = true;
      }
      // console.log(newjob);
    },
    //bottom轮播图
    async hotinfo() {
      const res1 = await this.api.hotinfo();
      this.res1 = res1.data.latestInfo;
      // console.log(res1.data.latestInfo);
    },
    switt(e) {
      clearInterval(this.timer);
      this.swi = e;
    },
    staswi() {
      this.timer = setInterval(() => {
        if (this.swi >= 4) {
          this.swi = 1;
        } else {
          this.swi++;
        }
      }, 3000);
    },

    skip66(id) {
      const { href } = this.$router.resolve({
        path: "TalentInfo", query: { id: id }
      });
      window.open(href, '_blank');
    },

    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timere = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timere);
        }
      }, 1);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {

    this.interval = setInterval(() => {
      this.currentPlaceholderIndex = (this.currentPlaceholderIndex + 1) % this.placeholderTexts.length; // 取模循环数组
      this.currentPlaceholder = this.placeholderTexts[this.currentPlaceholderIndex];
    }, 3000); // 每隔3秒钟切换一次placeholder文字

    if (this.$route.query.flag) {
      this.$confirm('感谢您的访问，已成功获得1积分', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        customClass: 'custom_wrap'
      }).then(() => {
      }).catch(() => {
      });
    }

    window.addEventListener("scroll", this.scrollToTop);
    this.timer = setInterval(() => {
      if (this.swi >= 4) {
        this.swi = 1;
      } else {
        this.swi++;
      }
    }, 3000);
  },
  created() {
    this.img();
    //this.getBanner();
    this.select("双一流院校", 1);
    //this.select1();
    //this.select2();
    //this.getHotPosition();
    this.getRecommendInstitution("全国站", 1);
    //this.getRenCaiZhuanXiang("", 1);
    this.hotinfo();
    //this.optimization1();
    this.getHotCity1();
    this.getTalentInfo1();
    // this.getSlide1();
  },
};
</script>

<style scoped lang="less">
.hotPosition {
  width: 1200px;
  margin: 32px auto 0 auto;
  padding: 0 16px 10px 16px;
  background-color: #fff;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.03);
  border-radius: 6px;

  .hpT {
    display: flex;
    justify-content: space-between;
    height: 70px;
    // line-height: 70px;

    .hpTL {
      display: flex;
      align-items: center;
      // width: 148px;
      font-size: 22px;
      color: #333;

      .underline {
        width: 3px;
        height: 22px;
        background-color: #3b85eb;
        margin-right: 14px;
      }

      .title {
        height: 22px;
        line-height: 22px;
        font-weight: 700;
      }
    }

    .hpTR {
      display: flex;
      align-items: center;

      div:nth-child(1) {
        display: flex;
        font-size: 13px;
        height: 21px;
        line-height: 21px;
        margin: auto 0;
        padding: 0 20px;
        background-color: #0079fe;
        color: #fff;
        border-radius: 40px;
        cursor: pointer;
      }

      div:nth-child(2) {
        text-align: center;
        margin: 0 10px;
        color: #d8d8d8;
      }

      div:nth-child(3) {
        font-size: 14px;
        color: #333;
        cursor: pointer;
      }
    }
  }

  .underlineOut {
    height: 1px;
    background-color: #EBEEF3;
    border-radius: 2px;
    margin-bottom: 20px;
    margin-top: 1px;
  }

  .hpB {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 4px;

    .Bbox {
      display: flex;
      height: 20px;
      line-height: 20px;
      width: 270px;
      white-space: nowrap;
      margin-bottom: 20px;
      cursor: pointer;

      .left {
        width: 36px;
        background-color: #F8664F1F;
        border-radius: 4px;
        margin-right: 5px;
        font-size: 12px;
        color: #F8664F;
      }

      .right {
        width: 229px;
        text-align: left;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.recommendInstitution {
  width: 100%;
  background-image: url(../img/recommendInstitution_bg.png);
  background-size: cover;

  .recommendInstitution_wrap {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 5px;
    padding-bottom: 5px;
  }

  .header {
    display: flex;
    color: #333;
    margin: 50px 0 50px 522px;

    .left {
      font-size: 27px;
      line-height: 42px;
      margin-right: 14px;
    }

    .right {
      margin-top: 15px;
      color: #aaa;
      font-size: 15px;
    }
  }

  .nav {
    display: flex;
    justify-content: space-around;
    height: 56px;
    background-color: #fff;
    align-items: center;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 0px 62px;
    padding-right: 24px;

    .item {
      width: 114px;
      height: 44px;
      line-height: 44px;
      border-radius: 10px;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
      transition: 0.2s all ease;

      &:hover {
        color: #222;
      }

    }

    .blue {
      -webkit-text-fill-color: transparent;
      background: linear-gradient(90deg, #428BF9 0%, #00B2FF 100%);
      background-clip: text;
      -webkit-background-clip: text;
      font-weight: bold;
      font-size: 17px;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 288px;
      height: 230px;
      background-color: #fff;
      margin-bottom: 16px;
      box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      cursor: pointer;
      margin-top: 30px;


      .img {
        border: 12px solid #fff;
        height: 96px;
        width: 96px;
        margin: -30px auto 10px auto;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0px 2px 5px 0px #0F298307;

        img {
          width: 72px;
          height: 72px;
        }
      }

      .name {
        height: 24px;
        color: #333;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-top: 10px;
      }

      .elName {
        height: 16px;
        font-size: 12px;
        color: #acafbb;
        margin: 8px 0 20px 0;
      }

      .num {
        display: flex;
        margin: 22px auto 24px auto;
        align-items: center;
        color: #141617;
        font-size: 13px;
        width: fit-content;
        height: 36px;
        border: 1px dashed #DDDDDD;
        border-radius: 2px;
        background: #FAFAFA;
        padding: 8px 12px;
        width: 254px;
        height: 82px;
        text-align: center;

        div {
          color: #444;
          font-size: 13px;
          // height: 36px;
          line-height: 24px;
          width: 100%;
        }
      }
    }
  }

  .offset_up {
    transition: 0.2s all ease;
  }

  .offset_up:hover {
    transform: translateY(-10px);
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 15px;

    .item {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      color: #2e65fd;
      background: #ECF5FF;
      border-radius: 8px;
      padding: 0 84px;
      cursor: pointer;

      span {
        background: linear-gradient(90deg, #428BF9 0%, #00B2FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
}

.introduce {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;


  .cover {
    padding: 32px 30px 36px 30px;
    background-color: #fff;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
  }

  .header {
    display: flex;
    color: #333;
    margin: 50px 0 50px 522px;

    .left {
      font-size: 27px;
      line-height: 42px;
      margin-right: 14px;
    }

    .right {
      margin-top: 15px;
      color: #aaa;
      font-size: 15px;
    }
  }

  .nav {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width: fit-content;
    height: 46px;
    margin: 0 auto 30px auto;
    // padding: 0 30px;
    background: #F8F9FA;
    border-radius: 8px;
    cursor: pointer;


    .item {
      padding: 0 30px;
      font-size: 15px;
      color: #666666;
    }

    .blue {
      padding: 0 30px;
      height: 50px;
      line-height: 50px;
      color: #287dfa;
      background: #EFF3FF;
      border-radius: 8px;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;

    .item {
      width: 222px;
      height: 254px;
      background-color: #fff;
      position: relative;
      border: 1px solid #EEEEEE;
      border-radius: 6px;
      cursor: pointer;

      .img {
        width: 222px;
        height: 130px;

        img {
          width: 222px;
          height: 130px;
        }
      }

      .name {
        display: flex;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        margin-left: 15px;
        margin-top: 15px;
        color: #333;

        .left {
          max-width: 165px;
        }
      }

      .content {
        width: 192px;
        color: #666;
        margin-left: 15px;
        line-height: 22px;
        height: 44px;
      }

      .tag {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 13px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 40px;

    .item {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      color: #2e65fd;
      background: #ECF5FF;
      border-radius: 8px;
      padding: 0 84px;
      cursor: pointer;

      span {
        background: linear-gradient(90deg, #428BF9 0%, #00B2FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
}

.wellChosen {
  width: 1200px;
  height: 552px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px 15px;
  margin-top: -142px;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.03);
  border-radius: 6px;

  .header {
    display: flex;
    justify-content: center;
    color: #333;
    margin: 0 0 30px 0;
    font-size: 28px;
    line-height: 42px;
    margin-right: 14px;
  }

  .nav {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    height: 38px;

    .item {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      color: #666;
      font-weight: 400;
      cursor: pointer;
      transition: 0.2s all ease;
      padding: 0 12px;

      &:hover {
        color: #0079fe;
      }

      div {
        width: 32px;
        height: 3px;
        background: #0079FE;
        border-radius: 10px;
        margin: 15px auto 0 auto;
      }
    }

    .wellBlue {
      color: #0079FE;
      font-size: 16px;
      font-weight: 700;
      margin-top: -2px;
    }
  }

  .body {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
    padding: 0 10px;

    .item {
      width: 368px;
      height: 150px;
      border: 1px solid #dadfe6;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 20px;
      text-align: left;
      cursor: pointer;
      transition: 0.1s all ease;

      &:hover {
        border: 1px solid #3870c9cc;
        box-shadow: 0px 5px 10px 0px #0F29830F;
      }

      .title {
        color: #222;
        font-size: 16px;
        line-height: 16px;
        height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tag {
        display: flex;
        margin: 16px 0 12px 0;

        div {
          font-size: 12px;
          border-radius: 8px;
          color: #7c8087;
          background-color: #f5f6f7;
          margin-right: 10px;
          padding: 5px 10px;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        line-height: 34px;
        background-color: #fff;

        .left {
          display: flex;
          width: 60%;

          .logo {
            width: 34px;
            height: 34px;
            margin-right: 10px;

            img {
              width: 34px;
              height: 34px;
            }
          }

          .name {
            font-size: 13px;
            color: #222;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

          }
        }

        .right {
          font-size: 13px;
          color: #afb3ba;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 25px;

    .item {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      color: #2e65fd;
      background: #ECF5FF;
      border-radius: 8px;
      padding: 0 84px;
      cursor: pointer;

      span {
        background: linear-gradient(90deg, #428BF9 0%, #00B2FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
}



.first_wrap {
  display: block;
  padding: 0 30px 20px 30px;
  width: 100%;
}

.img_wrap {
  cursor: pointer;
  width: 616px;
  height: 360px;
}


.first_info {
  display: none;
}

.hzkHome {
  width: 100%;
  min-height: 2000px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // .rew {
  //   position: fixed;
  //   bottom: 150px;
  //   right: 0px;
  //   width: 145px;
  //   height: 165px;
  //   z-index: 999;
  //   text-align: center;
  //   background-color: #fff;
  //   border-radius: 10px;
  //   font-weight: 400;
  //   font-style: normal;
  //   font-size: 13px;
  //   img{
  //     margin-left: 50%;
  //     transform: translateX(-50%);
  //   }
  // }
  // .iasd {
  //   width: 50px;
  //   height: 50px;
  //   position: fixed;
  //   bottom: 50px;
  //   right: 20px;
  //   z-index: 999;
  //   color: #007bff;
  //   font-size: 36px;
  //   line-height: 45px;
  //   background-color: #fff;
  //   border-radius: 50%;
  //   box-shadow: 0 0 5px rgb(202, 202, 202);
  //   cursor: pointer;
  // }
  // .iasd:hover {
  //   box-shadow: 0 0 5px #0079fe;
  // }
  // margin-bottom: 80px;
  .a-top {
    width: 100%;
    position: relative;
  }

  .a-top .div {
    z-index: 0;
    position: absolute;
    background-size: 100%;
    background-position: bottom;
    //height: 420px;
    width: 100%;
    left: 0;
    //-webkit-filter: blur(100px);
    //filter: blur(100px);
    overflow: hidden;
  }

  .a-top .home_banner_bg {
    z-index: 0;
    /*position: absolute;*/
    background-image: url(../img/home_banner_bg.png);
    background-size: 100%;
    background-position: bottom;
    background-position-y: -58px;
    width: 100%;
    height: 100%;
    left: 0;
    overflow: hidden;
  }

  .a-top-c {
    position: relative;
    height: 350px;
    width: 1200px;
    margin: 16px auto 22px auto;
    box-shadow: 0px 5px 10px 0px #0F29830F;

    .top_banner_wrap {
      position: absolute;
      left: 0px;
      width: 740px;
      height: 100%;

      .img {
        height: 350px;
        width: 740px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px 0px 0px 3px;
      }
    }

    .top_banner_list_wrap {
      position: absolute;
      right: 0px;
      background-color: rgba(255, 255, 255, 0.8);
      filter: blur(20);
      color: #333;
      width: 460px;
      height: 100%;
      padding: 22px 0;
      border-radius: 0px 3px 3px 0px;
      border-radius: 4px;

      .every_banner_list {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        width: calc(100% - 32px);
        height: 60px;
        line-height: 16px;
        font-size: 15px;
        margin-left: 12px;
        margin-right: 20px;
        padding: 22px;
        text-align: left;
        cursor: pointer;
        border-radius: 4px;
        //transition: all 0.3s ease-in-out;
        letter-spacing: 0px;
        list-style: square;
        color: #333;

        span {
          margin-left: -2px;
        }

        &::marker {
          font: menu
        }
      }

      .every_banner_list:hover {
        background: linear-gradient(90deg, #2570cb, #246ecb);
      }

      .mySpan {
        position: absolute;
        left: 34px;
        bottom: 18px;
        height: 28px;
        padding: 0px 8px;
        overflow: hidden;
        cursor: pointer;
        transition: 0.2s all ease;
        border-radius: 2px;
        border: 1px solid #EEEEEE;

        &:hover {
          border: 1px solid #dbe8fd;
        }

        &:hover .spanSon {
          color: #246ecb;
        }

        .spanSon {
          //border: 1px solid #EEEEEE;
          transition: 0.2s all ease;
          color: #333;
          font-size: 14px;
          line-height: 28px;
        }
      }

      // span:hover {
      //   text-shadow: 0px 0px 15px #007bff;
      // }

      // span::before {
      //   content: ">";
      //   position: absolute;
      //   top: 60%;
      //   transform: translate(0, -50%);
      //   right: 0;
      //   transition: all 0.3s ease-in-out;
      // }

      // span:hover::before {
      //   transform: translate(6px, -50%);
      // }
    }

  }

  .top_search_wrap {
    padding: 16px 0px;
    width: 100%;
    height: 78px;
    background-color: #FFFFFFCC;
    backdrop-filter: blur(20px);
    box-shadow: 0px 5px 10px 0px #0F29830F;
    padding-bottom: 0;

    .top_search {
      width: 1200px;
      margin: 0px auto;
      position: relative;

      .searchAnnouncement {
        position: absolute;
        left: 0px;
        width: 740px;
        height: 45px;
        border-radius: 3px;
        background: #FFFFFF;
        border: 1px solid rgba(204, 204, 204, 0.3);
        text-align: left;
        line-height: 45px;

        svg {
          position: absolute;
          top: -2px;
        }

        .search_title {
          color: #333333;
          font-size: 15px;
          margin-left: 12px;
          position: relative;
        }

        .search_title_text {
          margin-left: 24px;
        }

        input {
          position: absolute;
          height: 45px;
          line-height: 45px;
          background: none;
          outline: none;
          border: none;
          width: calc(100% - 162px);
          color: #333;
          margin: 0px 12px;
          font-size: 15px;
        }

        input::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #ccc;
          font-size: 15px;
        }

        input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #ccc;
          font-size: 15px;
        }

        input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #ccc;
          font-size: 15px;
        }

        input:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #ccc;
          font-size: 15px;
        }
      }

      .buttonGroup {
        position: absolute;
        right: 0px;

        .divided_line {
          margin-right: 46px;
          border-right: 1px solid rgba(204, 204, 204, 0.2);
          height: 32px;
        }

        button {
          cursor: pointer;
          width: 140px;
          height: 45px;
          border-radius: 3px;
          background: #308EFF;
          backdrop-filter: blur(20px);
          box-shadow: 0px 5px 10px 0px #0F29830F;
          margin-left: 20px;
          text-align: center;
          color: #fff;
          font-weight: bold;
          font-size: 16px;
        }

        .btn_find {
          background-color: #308EFF;
          transition: 0.2s all ease;
        }

        .btn_find:hover {
          transform: translateY(-2px);
        }

        .btn_recruit {
          background-color: #FF7640;
          transition: 0.2s all ease;
        }

        .btn_recruit:hover {
          transform: translateY(-2px);
        }
      }
    }
  }

  ul {
    /* width: 750px;
    margin: 10px auto 0 auto;*/

    li {
      float: left;
      height: 25px;
      line-height: 21px;
      font-size: 12px;
      padding: 3px 6px;
      border-radius: 2px;
      cursor: pointer;
    }

    .lititle {
      background-color: rgba(255, 255, 255, 0.1);
      float: left;
      color: #333;
      font-size: 12px;
      padding: 3px 6px;
      border-radius: 2px;
      width: 95px;
      height: 25px;
      line-height: 21px;
    }
  }
}

.contain {
  width: 100%;
  // height: 900px;
  margin-bottom: 50px;
  background-color: #f5f7fa;

  .contain1 {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background-color: #f9fafd;
    width: 1200px;
  }

  .c-left {
    width: 64%;
    height: 100%;

    .c-bottom {
      margin: 20px 0 30px 0;

      // position:fixed; bottom:0;  right: 42%;
      :deep(.el-pagination) {

        .btn-prev,
        .btn-next,
        .el-pagination__jump {
          position: relative;
          top: 3px;

          .el-input__inner {
            height: 28px;
            margin-top: 0;
          }
        }

        .el-pager {
          li {
            width: 35px;
            height: 35px;
            background-color: rgba(255, 255, 255, 1);
            border: 1px solid rgba(228, 228, 228, 1);
            line-height: 35px;
            color: #606266;
            border-radius: 3px;
            // &:hover {}
          }

          .active {
            background: #0079fe;
            color: #ffffff;
          }
        }
      }
    }

    .d-top {
      .el-input {
        /deep/.el-input__inner {
          height: 50px;
          padding: 0 180px 0 60px;
          border-radius: 10px;
        }

        /deep/.el-input__prefix {
          margin-left: 20px;
          margin-top: 13px;
          background-color: #ffffff;
          border: none;
          height: 0;
          color: #e36154;
          transform: scale(147%);
        }

        .d-middle {
          display: flex;
          margin-top: 13px;

          p {
            color: #0079fe;
            font-weight: 400;
            font-size: 18px;
            margin-right: 10px;
            cursor: pointer;
          }

          img {
            cursor: pointer;
            margin-right: 13px;
          }
        }
      }
    }

    .c-center {
      width: 100%;
      margin-top: 40px;

      .e-top {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        border-bottom: 1px solid #428bf9;

        // div {
        //   width: 50%;
        //   line-height: 50px;
        //   cursor: pointer;
        //   font-weight: 700;
        // }
        span {
          margin-left: auto;
          font-size: 14px;
          color: #999999;
          line-height: 50px;
          cursor: pointer;
        }

        span:hover {
          color: #0079fe;
        }

        div {
          position: absolute;
          height: 2px;
          background-color: #0079fe;
          bottom: 0;
          left: 0;
          transition: all 0.2s linear;
          transform: translateX(0);
        }

        .div1 {
          width: 92px;
          transform: translateX(0);
        }

        .div2 {
          width: 140px;
          transform: translateX(92px);
        }

        .div3 {
          width: 192px;
          transform: translateX(232px);
        }

        li {
          font-weight: 600;
          padding: 0 30px;
          line-height: 50px;
          cursor: pointer;
          transition: 0.2s transform linear;
        }

        .liact {
          color: #0079fe;
          transform: scale(1.375);
        }
      }

      .e-center {
        // position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0 20px 20px 20px;

        li {
          height: 140px;
          width: 100%;
          padding: 30px 40px;
          margin-top: 20px;
          background-color: #fff;
          border-radius: 10px;
          transition: all 0.3s linear;
          cursor: pointer;

          .img {
            float: left;
            width: 80px;
            height: 80px;
            overflow: hidden;
            border-radius: 50%;
          }

          .span {
            float: left;
            width: calc(100% - 180px);
            height: 80px;
            margin-left: 10px;
            // background-color: rgb(247, 19, 19);
            padding: 0px 0;
            text-align: left;
            margin-right: 10px;

            span {
              font-weight: 400;
              font-style: normal;
              font-size: 17px;
            }

            p {
              margin: 35px 0 0 0;
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              color: #666666;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 450px;
            }

            .ii {
              font-weight: 400;
              font-style: normal;
              font-size: 17px;

              i {
                font-size: 20px;
                color: #0079fe;
              }
            }
          }
        }

        li:hover {
          transform: translateY(-4px);
          box-shadow: 0px 0px 15px rgb(214, 214, 214);


        }

        li:hover .span_title {
          color: #0079fe;

        }
      }
    }
  }

  .c-right {
    width: 35%;
    height: 100%;
    margin-top: 80px;

    .q-top {
      width: 100%;

      img {
        cursor: pointer;
        float: right;
        transition: all 0.3s linear;
      }

      img:hover {
        transform: scale(1.05);
      }

      div {
        height: 157px;
        background-color: #f9fafd;
        margin-bottom: 35px;
      }
    }

    .q-bottom {
      background-color: #fff;
      border-top: 2px solid rgb(66, 139, 249);
      padding-bottom: 30px;
      width: 350px;
      margin-left: 35px;

      .w-top {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;

        p:first-child {
          font-weight: 700;
          font-size: 18px;
          color: rgb(51, 51, 51);
          text-align: left;
        }

        span {
          cursor: pointer;
          font-size: 14px;
          color: #999999;
        }

        span:hover {
          color: #0079fe;
        }
      }

      .w-bottom {
        width: 100%;

        li {
          position: relative;
          width: 100%;
          height: 165px;
          background-color: #fff;
          // border-radius: 10px;
          overflow: hidden;
          transition: all 0.2s linear;
          padding: 25px 50px;
          cursor: pointer;

          .li1 {
            display: flex;
            justify-content: space-between;

            span {
              font-weight: 400;
              font-style: normal;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }


          }

          .li2 {
            font-weight: 400;
            font-style: normal;
            font-size: 11px;
            color: #7f8c8d;
            margin-top: 25px;
            text-align: left;

            .p {
              font-weight: 400;
              float: right;
              text-align: right;
              font-style: normal;
              font-size: 14px;
              color: #428bf9;
              width: 38%;
            }
          }

          .li3 {
            margin-top: 25px;
            text-align: left;

            div {
              float: left;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              overflow: hidden;

              img {
                transform: translateX(-50%);
                margin-left: 50%;
                vertical-align: middle;
              }
            }

            span {
              margin-left: 45px;
              display: flex;
              justify-content: space-between;
              line-height: 38px;
              font-weight: 400;
              font-style: normal;
              font-size: 11px;
              color: #7f8c8d;
            }
          }
        }

        li:hover {
          // transform: translateY(-4px);
          // box-shadow: 5px 5px 25px rgb(201, 201, 201);
          color: #0079fe;
          background-color: rgba(79, 164, 255, 0.1);
        }

        li::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 5%;
          height: 1px;
          width: 90%;
          background-color: rgb(224, 231, 235);
        }

        li:hover::before {
          height: 0;
        }
      }
    }
  }
}

.bottom {
  width: 100%;
  // padding-bottom: 50px;
  background-color: #f5f9fd;

  .b-title {
    width: 1200px;
    height: 64px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;

    div {
      position: relative;
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      color: #333333;
      /*padding-left: 18px;*/
    }

    span {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-align: right;
      color: #333333;
    }

    span:hover {
      color: #0079fe;
    }
  }



  .b-carousel {
    /*width: 1200px; margin-top: 10px;*/
    height: 240px;

    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.03);
    border-radius: 6px;

    // background: #0079fe;
    .b-carousel1 {
      width: 1100px;
      margin-left: 50%;
      height: 100%;
      transform: translateX(-50%);
      // background-color: thistle;
      display: flex;
      justify-content: space-between;

      div {
        position: relative;
        padding: 40px 50px;
        width: 269px;
        height: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: left;
        border-radius: 4px;

        span {
          position: absolute;
          z-index: 10;
          font-family: "等线 Bold", "等线 Regular", "等线", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 20px;
          color: #fff;
        }

        p {
          position: absolute;
          top: 80px;
          z-index: 10;
          font-family: "等线 Bold", "等线 Regular", "等线", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 14px;
          color: #fff;
        }

        div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: all 0.3s linear;

          span {
            margin-top: 150px;
            left: 40px;
            font-size: 17px;
            cursor: pointer;
          }
        }

        div:hover {
          opacity: 1;
        }
      }
    }
  }

  .b-center {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 20px 32px;
    height: 400px;
    border-radius: 6px;
    background-color: #fff;
    overflow: hidden;

    .b-left {
      position: relative;
      float: left;
      width: 616px;
      height: 100%;
      border-radius: 6px;
      overflow: hidden;

      .bb {
        z-index: 10;
        padding: 16px 20px;
        width: 100%;
        height: 62px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        left: 0;

        span {
          float: left;
          width: 430px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 20px;
          color: #ffffff;
          font-weight: bold;
          cursor: pointer;
        }

        ul {
          margin-top: 4px;
          float: right;

          li {
            float: left;
            margin-left: 10px;
            height: 8px;
            width: 8px;
            border-radius: 4px;
            background-color: rgb(153, 153, 153);
            background: rgba(255, 255, 255, 0.698);
            border-radius: 4px;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: rgb(255, 255, 255);
            margin-top: 8px;
            cursor: pointer;
            transition: 0.2s all ease;
          }

          .li {
            width: 20px;
            background-color: #375BB9;

          }
        }
      }
    }

    .b-reight {
      float: right;
      width: 520px;
      height: 100%;
      background-color: #fff;
      // border-radius: 10px;

      .title {
        position: relative;
        height: 50px;
        width: 100%;

        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        // line-height: 50px;

        span:first-child {
          font-family: "Verdana Normal", Verdana, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 19px;
        }

        span:last-child {
          font-style: normal;
          font-size: 14px;
          color: rgb(153, 153, 153);
          cursor: pointer;
        }

        span:last-child:hover {
          color: #0079fe;
        }
      }

      .title::after {
        position: absolute;
        content: "";
        left: 0;
        // top: 50%;
        // transform: translateY(-50%);
        height: 50%;
        width: 5px;
        background-color: #0079fe;
      }

      ul {
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        margin-top: 1px;

        li {
          flex: 1;
          width: 520px;
          height: 44px;
          text-align: left;
          padding: 0 20px;
          line-height: 44px;
          font-family: "Verdana Normal", Verdana, sans-serif;
          font-weight: 400;
          font-style: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 15px;
          cursor: pointer;
          transition: all 0.3s linear;

          // background-color: #428bf9;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          div {

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        li:hover {
          color: #0079fe;
          /*background-color: rgba(0, 123, 254, 0.2);*/
        }
      }
    }
  }
}

.active {
  background-color: #428bf9;
  color: #ffffff;
}

.cusor_p {
  cursor: pointer;
}

.creat_time {
  margin-top: 38px;
  font-size: 15px;
}

.top_bc {
  height: 87px;
  padding: 16px 4px 12px 20px;
  border-radius: 8px 8px 0 0;
  background: linear-gradient(0deg, #EDF1F7, #EDF1F7);

  .title {
    width: 326px;
  }
}

.padding_0 {
  padding: 0 !important;
}

.padding_0 .bottom {
  padding: 11px 20px;
}

.every_tag {
  background-color: #FFFFFFCC !important;
}

.bottom_first_wrap {
  width: 1200px;
  height: 312px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-top: 22px;
  padding: 36px 0px;
}

.title_wrap {
  display: flex;
  align-content: start;
}

/*.title_wrap::after {
  content: "";
  width: 4px;
  height: 22px;
  position: absolute;
  left: 0px;
  background: #3B85EB;
}*/

.right_title_wrap {
  font-family: 'Microsoft YaHei' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 22px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.change_wrap {
  font-family: 'Microsoft YaHei UI' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  text-align: right;
  color: #375BB9 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  transition: 0.2s all ease;

  &:hover {
    filter: brightness(0.5);
  }

  img {
    transition: 0.2s all ease;
  }

  &:hover img {
    transform: rotate(90deg);

  }

  .bottom_last_content {
    width: 100%;
    padding: 24px 0 32px;
  }

}
</style>

<style scoped>
.flex_wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}


.more_btn {
  height: 48px;
  padding: 0px 24px !important;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF !important;
  background: linear-gradient(90deg, #3668E2 0%, #357FED 100%) !important;
  transition: 0.2s all ease;
}

.ml_33 {
  margin-left: 8px;
  height: 18px;
  line-height: 18px;
  transition: 0.2s all ease;
}

.more_btn:hover .ml_33 {
  margin-left: 12px;
}

.ml_33 {
  margin-left: 33px;
}

.header_title {
  text-align: left;

  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 53px;
  letter-spacing: 1px;
  color: #FFFFFF;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 28px;
  padding-top: 95px;
  display: flex;

}

.wellChosen_bg {
  background: url("../img/wellChosen_bg2.png");
}

.position_bc {
  width: 100%;
  height: 319px;
}

.ml_16 {
  margin-left: 16px;
}

.bottom_bc {
  background: url("../img/news_bg.png") 100% 100%;
  background-size: cover;
  border-bottom: 5px solid #375BB9;
}

.first {
  width: 1200px;
  margin: 0 auto;
}

.right_more {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  text-align: right;
  color: #375BB9 !important;
  transition: 0.2s all ease;
}

.right_more:hover {
  filter: brightness(0.5);
}

.second_title {
  width: 1200px;
  margin: 0 auto;
  margin-top: 65px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.m_12 {
  margin: 0 12px;
  font-size: 24px;
}

.more_news {
  background: #375BB9;
  border-radius: 4px;
  width: 156px;
  height: 42px;
  line-height: 42px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease;
}

.ml_33 {
  margin-left: 8px;
  height: 18px;
  line-height: 18px;
  transition: 0.2s all ease;
}

.more_news:hover .ml_33 {
  margin-left: 12px;
}

.w_1200 {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px;
  margin-top: 24px;
}



.first>>>.el-carousel__arrow {
  background: rgba(238, 238, 238, 0.6);
  border-radius: 2px;
  width: 24px;
  height: 56px;
  color: #9C9C9C;
  transition: 0.2s all ease;
}

.first>>>.el-carousel__arrow:hover {
  filter: brightness(0.8);
  color: #666;
}

.right_list_wrap {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;

}

.right_every_list {
  border-bottom: 1px solid #ddd;
  padding: 16px !important;

  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #333333;
}

.right_every_list:last-child {
  border-bottom: none;
}

.content_wrap {

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
  margin: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header_info {
  margin-top: 32px;
  margin-bottom: 26px;
}

.right_title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 290;
  letter-spacing: 3px;
  color: #555555;
}

.left_title {
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 32px;
  line-height: 53px;
  color: #292929;
  margin-top: 6px;
}

.p_24 {
  padding: 0 24px;

}

.img_info {
  padding-left: 24px;
  position: relative;
  top: -3px;
}

.imge_info:last-child .img_info {
  display: none;
}

.more_unit {
  width: 280px;
  height: 48px;
  padding: 0 !important;
  background: #FFFFFF !important;
  box-shadow: 0px 5px 10px rgba(15, 41, 131, 0.06);
  border-radius: 8px;
  backdrop-filter: blur(10px);
}

.ml_11 {
  margin-left: 4px;
  height: 20px;
  line-height: 20px;
  transition: 0.2s all ease;
}

.more_unit:hover .ml_11 {
  margin-left: 8px;
}

.font_color {
  background: linear-gradient(90deg, #428BF9 0%, #00B2FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: bold;
  font-size: 16px;
}

.imge_info {
  display: flex;
}
</style>
<!-- 移动端 -->
<style scoped> @media(max-width:576px) {

   .hzkHome .a-top .home_banner_bg {
     background-color: #00B2FF;
     /*  height: 210px;*/
   }

   .hzkHome .a-top-c {
     height: auto;
     margin: 0;
   }

   .hzkHome .a-top-c,
   .hzkHome .a-top-c .top_banner_wrap,
   .hzkHome .top_search_wrap .top_search .searchAnnouncement,
   .hzkHome .top_search_wrap .top_search .buttonGroup,
   .hzkHome .a-top-c .top_banner_list_wrap .mySpan {
     position: static;
     width: 100%;
   }

   .hzkHome .a-top-c .top_banner_wrap {
     width: 100%;
     height: 210px;
     padding: 14px 24px 17px;
     overflow: hidden;
   }

   .hzkHome .a-top-c .top_banner_wrap .img {
     width: calc(100% - 48px);
     height: 190px;
     padding: 14px 24px 17px;

   }

   .hzkHome .a-top-c .top_banner_list_wrap {
     position: static;
     width: 100%;
     height: 300px;
     padding-top: 17px;
   }

   .hzkHome .a-top-c .top_banner_list_wrap .mySpan {
     padding: 0 24px;
     height: auto;
     margin-top: 10px;
   }

   .hzkHome .top_search_wrap .top_search {
     width: 100%;
   }

   .hzkHome .top_search_wrap {
     height: auto;
   }

   .hzkHome .top_search_wrap .top_search .searchAnnouncement {
     margin: 20px 0;
   }

   .hzkHome .top_search_wrap .top_search .buttonGroup {
     padding: 10px 24px;
     display: flex;
   }

   .hzkHome .top_search_wrap .top_search .buttonGroup .divided_line {
     margin-right: 0;
     display: none;
   }

   .recommendInstitution .recommendInstitution_wrap {
     width: 100%;
     padding: 0 24px;
   }


   .recommendInstitution .nav {
     flex-wrap: wrap;
     height: auto;
     padding: 0 10px;
     justify-content: flex-start;
   }

   .recommendInstitution .nav .item {
     width: auto;
   }

   .imge_info:last-child .img_info {
     display: block;
   }

   .imge_info:nth-child(3n) .img_info {
     display: none;
   }

   .img_info {
     padding: 0 10px;
   }

   .recommendInstitution .body {
     justify-content: space-around;
   }

   .recommendInstitution .body .item {
     width: calc(100% - 48px);

   }

   .wellChosen_bg {
     padding: 0 24px;

   }

   .wellChosen {
     width: 100%;
     height: auto;
     padding-left: 0;
     padding-right: 0;
   }

   .wellChosen .nav {
     flex-wrap: wrap;
     justify-content: space-around;
   }

   .wellChosen .body {
     margin-top: 100px;
   }

   .wellChosen .body .item {
     width: calc(100% - 48px);
     margin: 0 25px 16px;
   }

   .wellChosen .body .item .title {
     width: 100%;

   }

   .wellChosen_bg {
     background-repeat: no-repeat;
   }

   .wellChosen .nav .item {
     height: 50px;
     padding: 0 4px;
   }

   .first,
   .bottom .b-title,
   .bottom_first_wrap,
   .bottom .b-carousel .b-carousel1 {
     width: 100%;
   }

   .first {
     padding: 0 24px;
   }

   .first .el-carousel__arrow {
     display: none;
   }

   .bottom .b-title {
     border-bottom: none;
   }

   .bottom_first_wrap {
     height: 740px;
     /* padding: 0;*/
   }

   .bottom .b-carousel,
   .bottom_first_wrap>>>.el-carousel__container {
     height: 740px !important;
   }

   .bottom .b-carousel .b-carousel1 {
     display: block;
   }

   .bottom .b-carousel .b-carousel1 {
     width: 350px;
     height: 160px;
     border-radius: 4px;
   }

   .bottom .b-carousel .b-carousel1 {
     margin-left: 61%;
   }

   .bottom .b-carousel .b-carousel1 div {

     margin: 10px 0;
   }

   .bottom .b-center {
     width: 100%;
     height: auto;
   }

   .w_1200,
   .bottom .b-center .b-lef,
   .header_title {
     width: 100% !important;
   }

   .b-left,
   .bottom .b-center .b-reight,
   .bottom .b-center .b-reight ul li,
   .second_title {
     width: 100% !important;

   }

   .change_wrap {
     display: none;
   }

   .img_wrap {
     width: 100%;
   }

   .bottom .b-center .b-left .bb span {
     width: 200px;
   }

   .bottom_last_content {
     padding-left: 24px;
     padding-right: 24px;
   }

 }
</style>
